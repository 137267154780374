import * as React from 'react';
import Tippy from '@tippyjs/react';
import { StaticImage } from "gatsby-plugin-image"
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import 'tippy.js/dist/tippy.css';
import './tippy-dark-theme.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#E1E1E1',
      borderRadius: 15,
      maxWidth: 246,
      height: 132,
      padding: 20,
      position: 'relative'
    },
    innerContainer: {
      position: 'absolute',
      transform: 'translate(-7%, -88%)',
    },
    imageContainer: {
      backgroundColor: theme.palette.text.primary,
      borderRadius: '50%',
      padding: 15,
      width: 90,
      height: 90,
      display: "flex",
      alignItems: "center"
    },
    text: {
      marginTop: 20,
      overflow: 'hidden',
      textAlign: 'center',
      // line clamps
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 4,
      lineHeight: 1.13
    },
    title: {
      width: 68,
      opacity: 0.6,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(15)
    },
    list: {
      paddingLeft: 18,
      textAlign: 'start'
    }
  })
);

interface DetailsCellProps {
  title: string;
  description?: string;
}

const IMAGE_PROPS = {
  width: 63,
  quality: 100,
  formats: ["AUTO"],
  placeholder: "blurred"
}

const IMAGES = {
  "about us": <StaticImage {...IMAGE_PROPS} src="../../images/lightbulb.png" alt="about us" />,
  "mission": <StaticImage {...IMAGE_PROPS} src="../../images/mission.png" alt="mission" />,
  "values": <StaticImage {...IMAGE_PROPS} src="../../images/values.png" alt="values" />,
  "vision": <StaticImage {...IMAGE_PROPS} src="../../images/values.png" alt="values" />
}

const parseDescription = (title: string, description?: string): [React.ReactNode, React.ReactNode] => {
  const classes = useStyles();
  if (!description) {
    return ['N/A', 'N/A'];
  }

  if (title !== 'values') {
    return [description, description];
  }


  const tooltip = description
  .split(',')
  .map(value => (<li key={value}>{value}</li>));
  let values = tooltip;

  if (tooltip.length > 3) {
    values = tooltip.slice(0, 3);
    const total = tooltip.length - values.length;
    values.push(<li key='more'>{`+ ${total} more`}</li>)
  }

  return [
    <ul className={classes.list}>{values}</ul>,
    <ul className={classes.list}>{tooltip}</ul>
  ];
}

export const DetailsCell = ({ title, description }: DetailsCellProps) => {
  const classes = useStyles();
  const [parsedDescription, tooltip] = parseDescription(title, description);

  return (
    <Box className={classes.root} display='flex' flexDirection='column'>
      <Box className={classes.innerContainer} display='flex' alignItems='center'>
        <Typography className={classes.title}>{title}</Typography>
        <Box className={classes.imageContainer}>
          {IMAGES[title]}
        </Box>
      </Box>
      <Tippy content={tooltip} theme="dark" hideOnClick={false}>
        <Box className={classes.text}>{parsedDescription}</Box>
      </Tippy>
    </Box>
  );
}
