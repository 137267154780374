import * as React from 'react';
import { Box, GridList, GridListTile } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StaticImage } from "gatsby-plugin-image"

type ScoreValue = 0.5 | 1 | 1.5 | 2 | 2.5 | 3 | 3.5 | 4 | 4.5 | 5 | 5.5 | 6 |
                  6.5 | 7 | 7.5 | 8 | 8.5 | 9 | 9.5 | 10;

type ImageValue = 0.5 | 0 | 1;

const IMAGE_PROPS = {
  width: 35,
  quality: 100,
  formats: ["AUTO"],
  placeholder: "blurred"
}

const PARTIAL = <StaticImage {...IMAGE_PROPS} src="./images/partial.png" alt="" />;
const YELLOW = <StaticImage {...IMAGE_PROPS} src="./images/full-yellow.png" alt="" />;
const BLACK = <StaticImage {...IMAGE_PROPS} src="./images/black.png" alt="" />;

const buildScoringElements = (score: ScoreValue): ImageValue[] => {
  const elements = [];
  for (let i = 0; i < 10; i++) {
    elements.push(0);
  }

  let total = score;
  let hasHalf = false;
  if (total % 1 === 0.5) {
    hasHalf = true;
    total -= 0.5;
  }

  let idx = 0;
  for (idx = 0; idx < total; idx++) {
    elements[idx] = 1;
  }

  if (hasHalf) {
    elements[idx] = 0.5;
  }

  return elements;
}


interface ScoreProps {
  score: number;
}

const getImage = (value: ImageValue) => {
  if (value === 0.5) {
    return PARTIAL;
  }

  if (value === 1) {
    return YELLOW;
  }

  return BLACK;
}

const useStyles = makeStyles(() =>
  createStyles({
    imageContainer: {
      display: 'flex',
      justifyContent: 'center'
    }
  })
);

export const Score = ({ score }: ScoreProps) => {
  const classes = useStyles();
  const scores = buildScoringElements(score);

  return (
    <Box style={{ width: 280 }}>
      <GridList cellHeight='auto' cols={5} spacing={10}>
        {scores.map((value, index) => (
          <GridListTile key={index} className={classes.imageContainer}>
            {getImage(value)}
          </GridListTile>
        ))}
      </GridList>
    </Box>
  );
}
