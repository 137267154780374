import { CHART_BACKGROUND_COLORS, CHART_BACKGROUND_BORDER_COLORS } from '../constants';

const DATA_LABELS_CONFIG = {
  labels: {
    index: {
      align: 'end',
      anchor: 'end',
      color: () => '#000000', // ctx.dataset.borderColor;
      font: {
        size: 18,
        family: "'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
      },
      formatter: (value: number, ctx: any) => {
        const label = ctx.chart.data.labels[ctx.dataIndex];
        return `${label} \n${value}%`;
      },
      offset: 20,
      opacity: (ctx: any) => ctx.active ? 1 : 0.80,
      display: 'auto'
    },
  }
};

export const buildChartData = (title: string, data: number[], labels: string[]) => {
  const backgroundColors = data.map((_, index) => (
    CHART_BACKGROUND_COLORS[index % CHART_BACKGROUND_COLORS.length])
  );

  const borderColors = data.map((_, index) => (
    CHART_BACKGROUND_BORDER_COLORS[index % CHART_BACKGROUND_BORDER_COLORS.length])
  );

  return {
    labels,
    datasets: [
      {
        data,
        label: title,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
        datalabels: DATA_LABELS_CONFIG,
        hoverOffset: 10,
        spacing: 2,
        cutout: '70%'
      }
    ]
  };
}
