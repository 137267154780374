import * as React from 'react';
import { graphql } from "gatsby"

import { CompanyDetailsPage } from '../components/details';


// slug is a property passed in from gatsby-node.js, i.e. context value
// This data is then passed into the component as props.data
export const productQuery = graphql`
  query($id: String!) {
    companiesJson(id: {eq: $id}) {
      name
      logo
      about
      mission
      vision
      values
      founded
      employees
      location
      website
      careerPage
      dateUpdated
      metrics {
        company {
          poc
          lgbtq
          disabled
          pmg
          race {
            key
            value
          }
          gender {
            key
            value
          }
        }
        leadership {
          poc
          lgbtq
          disabled
          pmg
          race {
            key
            value
          }
          gender {
            key
            value
          }
        }
      }
    }
  }
`;

const CompanyDetails = ({ data }) => {
  const company = data.companiesJson;
  return <CompanyDetailsPage company={company} />;
};

// must export as default
export default CompanyDetails;
