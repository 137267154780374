import * as React from 'react';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { DataCell } from './DataCell';
import { Doughnut } from '../common/dataviz/doughnut';

import * as styles from './DoughnutCell.module.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chart: {
      padding: '0 8px'
    }
  })
);

interface Data {
  key: string;
  value?: number;
}

interface DoughnutCellProps {
  label: string;
  description: string;
  descriptionHelper?: string;
  data: Data[];
  stat?: number;
  showStats: boolean;
  isDiversity: boolean;
}

const LABEL_TRANSLATION = {
  "noAnswer": "n/a"
}

export const DoughnutCell = ({
  description,
  descriptionHelper,
  label,
  data,
  stat,
  showStats,
  isDiversity
}: DoughnutCellProps) => {
  const classes = useStyles();
  const filteredResultSet = data
    .filter(result => result.value)
    .sort((a, b) => b.value - a.value);
  const normalizedData = filteredResultSet.map(result => result.value);
  const labels = filteredResultSet.map(result =>
    LABEL_TRANSLATION[result.key] ?
    LABEL_TRANSLATION[result.key] :
    result.key
  );
  let normalizedStat = stat;

  if (normalizedData.length === 0) {
    normalizedStat = 0;
  }

  return (
    <DataCell 
      label={label}
      description={description}
      descriptionHelper={descriptionHelper}
      stat={normalizedStat}
      showStats={showStats}
    >
      <Box className={classes.chart}>
          <Doughnut
            className={isDiversity? styles.diversity : styles.gender}
            title={label}
            data={normalizedData}
            labels={labels}
            usePercentageFormat
          />
      </Box>
    </DataCell>
  );
}
