import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const PADDING = '22px';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '2px solid #E1E1E1',
      borderRadius: 15,
      padding: `${PADDING} 0`,
      height: '100%',
      position: 'relative'
    },
    children: {
      width: '100%'
    },
    label: {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.text.secondary,
      textAlign: 'center',
      padding: '12px 40px',
      height: 43
    },
    labelText: {
      fontWeight: 700,
      lineHeight: 1.2
    },
    description: {
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(36),
      margin: `0 ${PADDING} 0 0`,
      textAlign: 'right',
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.typography.pxToRem(20)
      }
    },
    descriptionHelper: {
      fontSize: theme.typography.pxToRem(12),
      opacity: 0.5,
      position: 'absolute',
      bottom: 6,
      textAlign: 'center',
      width: '100%',
      [theme.breakpoints.down("xs")]: {
        fontSize: theme.typography.pxToRem(11)
      }
    }
  })
);

interface DataCellProps {
  label: string;
  description: string;
  descriptionHelper?: string;
  stat: number;
  children: React.ReactNode;
  showStats?: boolean;
}

export const DataCell = ({
  children,
  label,
  description,
  descriptionHelper,
  stat,
  showStats = true
}: DataCellProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} display='flex' flexDirection='column'>
      <Box display='flex' justifyContent='space-between'>
        <Box className={classes.label}>
          <Typography className={classes.labelText}>
            {label}
          </Typography>
        </Box>
        <Box style={showStats ? {} : {opacity: 0}}>
          <Typography className={classes.description}>{description}</Typography>
          <Typography className={classes.description}>
            {stat ? `${stat}%` : `n/a`}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.children} alignSelf='center'>
        {children}
      </Box>
      {descriptionHelper &&
        <Typography className={classes.descriptionHelper}>
          {descriptionHelper}
        </Typography>
      }
    </Box>
  );
}
