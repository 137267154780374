import * as React from 'react';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StaticImage } from "gatsby-plugin-image"

import { DataCell } from './DataCell';
import { Score } from '../common/dataviz/score';

const IMAGE_PROPS = {
  width: 63,
  quality: 100,
  formats: ["AUTO"],
  placeholder: "blurred"
}

const IMAGES = {
  "w/ disabilities": <StaticImage {...IMAGE_PROPS} src="./images/accessability.png" alt="accessability" />,
  "lgbqt+": <StaticImage {...IMAGE_PROPS} src="./images/lgbtq.png" alt="lgbqt+" />,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageContainer: {
      backgroundColor: theme.palette.text.primary,
      borderRadius: '50%',
      padding: 18,
      width: 90,
      height: 90,
      display: "flex",
      alignItems: "center",
      margin: "5px 0 25px 0"
    },
  })
);

interface ScoreCellProps {
  label: string;
  description: string;
  value: number;
}

const HALF = 5;

const convertNumberToScore = (value: number) => {
  const mod = value % HALF;
  if (mod === 0) {
    return value / 10;
  }

  if ((HALF - mod) > (HALF / 2)) {
    return (value - mod) / 10;
  }

  return (value + (HALF - mod)) / 10;
}

export const ScoreCell = ({ label, description, value }: ScoreCellProps) => {
  const classes = useStyles();
  const score = convertNumberToScore(value);
  return (
    <DataCell label={label} description={description} stat={value}>
      <Box display='flex' alignItems='center' flexDirection="column">
        <Box className={classes.imageContainer}>
          {IMAGES[description]}
        </Box>
        <Score score={score} />
      </Box>
    </DataCell>
  );
}
