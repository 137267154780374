import * as React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Box, Container, Grid, Hidden, Tab, Tabs } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Layout } from '../common/layout';
import { Seo } from '../common/seo';
import { DoughnutCell } from './DoughnutCell';
import { CompanyDetailsHeader } from './CompanyDetailsHeader';
import { DetailsCell } from './DetailsCell';
import { ScoreCell } from './ScoreCell';
import { trackGAEvent } from '../../utils/analytics';

interface Data {
  key: string;
  value?: number;
}

interface Company {
  name: string;
  about: string;
  mission: string;
  values: string;
  vision: string;
  careerPage: string;
  metrics: {
    company: {
      race: Data[];
      gender: Data[];
      poc?: number;
      lgbtq?: number;
      disabled?: number;
      pmg?: number;
    };
    leadership: {
      race: Data[]
      gender: Data[];
      poc?: number;
      lgbtq?: number;
      disabled?: number;
      pmg?: number;
    };
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsContainer: {
      borderBottom: '1px solid #dee2e6',
      margin: "32px 5px",
    },
    tab: {
      textTransform: 'lowercase',
      fontSize: theme.typography.pxToRem(16),
      textDecoration: 'none',
      color: 'inherit'
    }
  })
);

interface CompanyDetailsPageProps {
  company: Company;
}

const buildTitle = (name: string) => `${name} Diversity Profile & Employee Demographics`;
const buildDescription = (name: string) =>
  `Get an insider's look at ${name}’s diversity.
  Rebolt provides a detailed breakdown of ${name}'s workforce by gender,
  race, sexual orientation, and more`;

const getMetricKey = (value: number): string => value === 1 ? 'leadership' : 'company';

export const CompanyDetailsPage = ({ company }: CompanyDetailsPageProps) => {
  const classes = useStyles();
  const [tabState, setTabState] = React.useState(0);
  const { about, careerPage, metrics, mission, name, values, vision } = company;
  const metricKey = getMetricKey(tabState);
  const { race, gender, lgbtq, disabled, poc, pmg } = metrics[metricKey];

  const onTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    // carreers link tab
    if (newValue === 2) {
      return;
    }

    setTabState(newValue);
    trackGAEvent('details.tab_change', { tab: getMetricKey(newValue) })
  };

  return (
    <Layout>
      <Seo title={buildTitle(name)} description={buildDescription(name)} />
      {/* -8px matches the spacing from the grid */}
      <Grid container spacing={1} style={{ margin: '70px 0 35px -8px'}} alignItems="flex-end">
        <Grid item xs={11} md={5} lg={4}>
          <CompanyDetailsHeader company={company} />
        </Grid>
          <Hidden smDown>
            <Grid item md lg>
              <DetailsCell title="about us" description={about} />
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid item md lg>
              <DetailsCell title="mission" description={mission} />
            </Grid>
          </Hidden>
          <Hidden mdDown>
            <Grid item lg>
              <DetailsCell
                title={values ? "values" : "vision"}
                description={values ? values : vision}
              />
            </Grid>
          </Hidden>
      </Grid>
      <Container disableGutters maxWidth="md" style={{ padding: '0 25px 45px 25px' }}>
        <Tabs
          value={tabState}
          indicatorColor="primary"
          className={classes.tabsContainer}
          onChange={onTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab className={classes.tab} label="overall" />
          <Tab className={classes.tab} label="leadership" />
          <Tab className={classes.tab}
            label={
              <OutboundLink href={careerPage} target="_blank" rel='noopener noreferrer'>
                <Box display="flex" alignItems="center">
                  open roles
                  <LaunchIcon style={{marginLeft: 5}} fontSize="small" />
                </Box>
              </OutboundLink>
            }
            aria-label="external-link-jobs"
          />
        </Tabs>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <DoughnutCell
              label='race / ethnicity'
              description='poc'
              descriptionHelper='people of color (black, latinx, asian, indigenous, etc)'
              data={race}
              stat={poc}
              showStats
              isDiversity
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ScoreCell label='sexual orientation' description='lgbqt+' value={lgbtq} />
          </Grid>
          <Grid item xs={12} md={6}>
            <ScoreCell label='disability' description='w/ disabilities' value={disabled} />
          </Grid>
          <Grid item xs={12} md={6}>
            <DoughnutCell
              label='gender identity'
              description='pmg'
              descriptionHelper='people of marginalized genders (female and nonbinary)'
              data={gender}
              stat={pmg}
              showStats
              isDiversity={false}
            />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
};
