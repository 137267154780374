const RGB_COLORS: string[] = [
  '255,211, 0',
  '225, 225, 225',
  '254, 226, 140',
  '255, 153, 0',
  '196, 196, 196',
  '255, 83, 0',
  '15, 15, 15',
  '117, 117, 117',
  '255, 168, 119'
];

export const CHART_BACKGROUND_COLORS = RGB_COLORS.map((color) => (
  `rgba(${color}, 0.95)`
));

export const CHART_BACKGROUND_BORDER_COLORS = RGB_COLORS.map((color) => (
  `rgba(${color}, 1)`
));
