import * as React from 'react';
import { Doughnut as DoughnutChart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { buildChartData } from './doughnut.utils';


const DEFAULT_OPTIONS = {
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      enabled: true,
      callbacks: {}
    }
  },
  aspectRatio: 4/3,
  layout: {
    padding: 58
  }
};

interface DoughnutProps {
  className?: string;
  title: string;
  data: number[];
  labels: string[];
  usePercentageFormat?: boolean
}

const percentageFormatter = (context) => {
  const { label, formattedValue } = context;
  return `${label}: ${formattedValue}%`
}

export const Doughnut = ({ className, title, data, labels, usePercentageFormat }: DoughnutProps) => {
  const chartData = buildChartData(title, data, labels);

  if (usePercentageFormat) {
    DEFAULT_OPTIONS['plugins']['tooltip']['callbacks']['label'] = percentageFormatter;
  }

  return (
    <DoughnutChart
      width={600}
      className={className}
      data={chartData}
      options={DEFAULT_OPTIONS}
      plugins={[ChartDataLabels]}
    />
  );
}
