import * as React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Avatar, Box, Grid, SvgIcon, Typography, } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GroupIcon from '@material-ui/icons/Group';
import LanguageIcon from '@material-ui/icons/Language';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      width: 124,
      height: 124,
      padding: 22,
      color: theme.palette.text.primary,
      backgroundColor: 'white'
    },
    avatarImg: {
      marginBottom: 0,
      height: 'auto'
    },
    companyTag: {
      backgroundColor: theme.palette.primary.main,
      padding: 20,
      borderRadius:'0px 15px 15px 0px',
      maxWidth: 395,
      boxShadow: '0px 2px 10px 2px rgba(0, 0, 0, 0.2)'
    },
    detailsContainer: {
      marginLeft: 20
    },
    details: {
      alignItems: 'center'
    },
    icon: {
      marginRight: 5
    },
    name: {
      marginBottom: 10,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(30),
    },
    text: {
      fontSize: theme.typography.pxToRem(13),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  })
);

interface CompanyDetailsHeaderProps {
  company: any;
}

const formatNumber = (value): string => {
  if (value < 1000) {
    return `${value}`;
  }

  if (value < 1000000) {
    const formattedValue = value / 1000;
    if (Number.isInteger(formattedValue)) {
      return `${formattedValue}k`;
    }

    return `${Math.floor(formattedValue)}k+`;
  }

  const formattedValue = value / 1000000;
  if (Number.isInteger(formattedValue)) {
    return `${formattedValue}M`;
  }

  return `${Math.floor(formattedValue)}M+`;

}

export const CompanyDetailsHeader = ({ company }: CompanyDetailsHeaderProps) => {
  const { name, logo, founded, location, website, employees } = company;
  const classes = useStyles();

  const formattedEmployees = website.replace('https://', '');
  const renderDetail = (value: string, icon: React.ElementType) => (
    <Box className={classes.details} display='flex'>
      <SvgIcon className={classes.icon} component={icon} />
      <Typography className={classes.text}>{value}</Typography>
    </Box>
  );

  return (
    <Box display='flex'>
      <Box display='flex' className={classes.companyTag}>
        <Avatar
          classes={{ root: classes.avatar, img: classes.avatarImg }}
          alt={`${name} logo`}
          src={logo}
        />
        <Box className={classes.detailsContainer}>
          <Typography className={classes.name} variant='h1'>{name}</Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              {renderDetail(founded, DateRangeIcon)}
            </Grid>
            <Grid item xs={12} md={8}>
              {renderDetail(location, LocationOnIcon)}
            </Grid>
            <Grid item xs={12}>
              {renderDetail(`${formatNumber(employees)} employees`, GroupIcon)}
            </Grid>
            <Grid item xs={12}>
              <OutboundLink href={website} target='_blank' style={{color: 'inherit'}}>
                {renderDetail(website, LanguageIcon)}
              </OutboundLink>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
